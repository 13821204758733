<template>
    <div>
        <div class="modal fade" id="afiliationModal" tabindex="-1" aria-labelledby="afiliationModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="afiliationModalLabel" class="font-weight-bold">{{ModalTitle}}{{ContractorCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold required">Kode</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="ContractorCode" v-model="ContractorCode" class="font-weight-bold" />
                                <label id="errorContractorCode" class="form-error" style="display: none; color: red;"></label>
                            </CCol>               
                        </CRow>
                        <CRow>
                             <CCol sm="2">
                                <label class="form-label font-weight-bold required">Nama</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="ContractorName" v-model="ContractorName" class="font-weight-bold" />
                                <label id="errorContractorName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>    
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                     
                        </CRow>
                        <hr>

                        <CTabs>
                            <CTab title="Profil" id="tab-1" active>
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Provinsi</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="Province" class="pb-3" :options="ProvinceData" v-model="Province" @input="onChange()" />
                                            <label id="errorProvince" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Bebas Pajak</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="TaxFree"/> <label>Iya</label>
                                            </div>
                                            <label id="errorTaxFree" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Kota</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="City" class="pb-3" :options="CityData" v-model="City"/>
                                            <label id="errorCity" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>     
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">Address</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                                            <label id="errorAddress" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone1" v-model="Phone1" class="font-weight-bold" />
                                            <label id="errorPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone2" v-model="Phone2" class="font-weight-bold" />
                                            <label id="errorPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Handphone 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone1" v-model="HandPhone1" class="font-weight-bold" />
                                            <label id="errorHandPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                       <CCol sm="2">
                                            <label class="form-label font-weight-bold">Handphone 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone2" v-model="HandPhone2" class="font-weight-bold" />
                                            <label id="errorHandPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">NPWP</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NPWP" v-model="NPWP" class="font-weight-bold" />
                                            <label id="errorNPWP" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Grade</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Grade" v-model="Grade" class="font-weight-bold" />
                                            <label id="errorGrade" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">NIK</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NIK" v-model="NIK" class="font-weight-bold" />
                                            <label id="errorNIK" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Catatan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                            <label id="errorNote" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CTab>
                            <CTab title="Kontak Person" id="tab-2">
                                <div class="p-3">
                                    <datasource ref="dataSource" :data="this.GridData" :page-size="10" :schema-model-fields="this.SchemaModel"/>

                                    <kendo-grid ref="gridContactPerson"
                                        :data-source-ref="'dataSource'"
                                        :pageable="true"
                                        :editable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :toolbar="['create']"
                                        :resizable="true"
                                        >

                                        <kendo-grid-column  :field="'contact_name'"
                                                            :title="'Nama'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'contact_phone'"
                                                            :title="'Nomor Telepon'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'contact_position'"
                                                            :title="'Jabatan'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'account_name'"
                                                            :title="'Atas Nama'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'account_number'"
                                                            :title="'Nomor Rekening'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                        
                                        <kendo-grid-column  :field="'npwp'"
                                                            :title="'NPWP'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :title="'&nbsp;'"
                                                            :attributes="{ class: 'k-text-center' }"
                                                            :command="['destroy']"
                                                            :width= 200></kendo-grid-column>
                                    </kendo-grid>
                                </div>
                            </CTab>
                        </CTabs>
                        <CButton type="button" @click="saveClick()" id="saveButton" class="save-button" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import afiliationServices from '../Script/AfiliationServices.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'AfiliationForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            SaveType : '',
            Error: 0,
            ProfileError : 0,
            Id : '',

            ContractorCode : '',
            ContractorName : '',
            Status : '',
            StatusData: [],

            //Profile
            Province : '',
            ProvinceData : [],
            TaxFree : '',
            City : '',
            CityData : [],
            Address : '',
            Phone1 : '',
            Phone2 : '',
            HandPhone1 : '',
            HandPhone2 : '',
            NPWP : '',
            Grade : '',
            NIK : '',
            Note : '',
            
            //grid
            GridData: [],
            SchemaModel: {
                contact_name: { type: "string", editable: true, validation: {required: true}},
                contact_phone: { type: "string", editable: true, validation: {required: true}},
                contact_position: { type: "string", editable: true, validation: {required: true}},
                account_name: { type: "string", editable: true, validation: {required: true}},
                account_number: { type: "string", editable: true, validation: {required: true}},
                npwp: { type: "string", editable: true, validation: {required: true}},
            },
        }
    },
    methods: {
        addClick(){   
            $(".form-error").css("display", "none");  
            this.ModalTitle = 'Add Contractor: ';

            this.ContractorCode = 'Auto';
            this.ContractorName = '';
            this.StatusData = [{value: 'N', label: 'New'}];
            this.Status = this.StatusData[0].value;
            
            this.TaxFree = false;
            this.Province = 'DKI Jakarta';

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == "DKI Jakarta");
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == "Jakarta Utara");

            this.Address = '';
            this.Phone1 = '';
            this.Phone2 = '';
            this.HandPhone1 = '';
            this.HandPhone2 = '';
            this.NPWP = '';
            this.Grade = '';
            this.NIK = '';
            this.Note = '';

            //grid 
            this.GridData = [];

            this.SaveType = 'Add';
            $('#tab-1').trigger('click');
            document.getElementById('ContractorCode').readOnly = false;
            window.$('#afiliationModal').modal('show');
        },
        async editClick(id, View) {
            var data = await afiliationServices.getDetailContact(id);

            $(".form-error").css("display", "none");  
            $(".save-button").show();

            this.ModalTitle = 'Edit Contractor: ';
            this.Id = data.contact_id;
            this.ContractorCode = data.contact_code;
            this.ContractorName = data.contact_name;
            
            this.StatusData = [ {value: 'A', label: 'Active'},
                                {value: 'I', label: 'Inactive'}];
            this.Status = data.status=="N"?"A":data.status;
            
            var profileData = data.metadata.profile;
            this.TaxFree = profileData.taxfree;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == profileData.province);
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == profileData.city);

            this.Address = profileData.address;
            this.Phone1 = profileData.phone1;
            this.Phone2 = profileData.phone2;
            this.HandPhone1 = profileData.handphone1;
            this.HandPhone2 = profileData.handphone2;
            this.NPWP = profileData.npwp;
            this.Grade = profileData.grade;
            this.NIK = profileData.nik;
            this.Note = profileData.note;

            //grid 
            this.GridData = this.$globalfunc.objectToArrayConverter(data.metadata.contact_person, 'Affiliation-ContactPerson');
            
            this.SaveType = 'Edit';
            $('#tab-1').trigger('click');
            document.getElementById('ContractorCode').readOnly = true;
            this.reloadDetail();

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Contractor: ';
            }

            window.$('#afiliationModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            this.ProfileError = 0;

            $(".form-error").css("display", "none");

            if(this.ContractorCode == ''){
                this.errorShow('errorContractorCode');
            }
            if(this.ContractorName == ''){
                this.errorShow('errorContractorName');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
                this.ProfileError++;
            }
            if(this.NPWP == ''){
                this.errorShow('errorNPWP');
                this.ProfileError++;
            }
            if(this.NIK == ''){
                this.errorShow('errorNIK');
                this.ProfileError++;
            }
            if(this.ProfileError != 0){
                this.$swal("Error", "Terdapat error pada tab profil", "error");
            }else{
                var gridData = this.$refs.gridContactPerson.kendoWidget().dataSource._data;
                if(gridData.length <= 0){
                    this.$swal("Error", "Kontak person tidak boleh kosong", "error");
                    this.Error++;
                }
            }
        },
        saveClick() {
            this.inputValidation();

            //Contact Person Grid Validation
            var gridData = this.$refs.gridContactPerson.kendoWidget().dataSource._data;
            var contactPerson = [];
            for (let i = 0; i < gridData.length; i++) {
                var str = { contact_name: gridData[i].contact_name,
                            contact_phone: gridData[i].contact_phone,
                            contact_position: gridData[i].contact_position,
                            account_name: gridData[i].account_name,
                            account_number: gridData[i].account_number,
                            npwp: gridData[i].npwp}
                contactPerson.push(str)
            }
            var contactPersonGridValidation = afiliationServices.contactPersonGridValidation(contactPerson);

            if(contactPersonGridValidation == true){
                this.$swal("Error", "Nama pada kontak person tidak boleh duplikat", "error");
                this.Error++;
            }

            if(this.Error == 0){
                const profile = {
                    province : this.Province.value,
                    taxfree : this.TaxFree,
                    city :  this.City.value,
                    address : this.Address,
                    phone1 : this.Phone1,
                    phone2 : this.Phone2,
                    handphone1 : this.HandPhone1,
                    handphone2 : this.HandPhone2,
                    npwp : this.NPWP,
                    grade : this.Grade,
                    nik : this.NIK,
                    note : this.Note
                }

                const contact_person = contactPerson

                const metadata = {
                    profile: profile,
                    contact_person: contact_person
                }

                const afiliation = {
                    contact_code: this.ContractorCode,
                    contact_type: "Afiliation",
                    contact_name: this.ContractorName,
                    status: this.Status,
                    metadata: metadata,
                    attachment1: null,
                    attachment2: null,
                }

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : afiliation
                    }

                    afiliationServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#afiliationModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : parseInt(this.Id),
                        data : afiliation
                    }

                    afiliationServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#afiliationModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        onChange(){
            this.City = '';
            if (this.Province == null){
                this.CityData = []
            }
            else{
                this.CityData = this.$globalfunc.getCityData(this.Province.value);
            }
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
    }
}
</script>

<style scoped>
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>