<template>
    <div>
        <div class="modal fade" id="distributorModal" tabindex="-1" aria-labelledby="distributorModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="distributorModalLabel" class="font-weight-bold">{{ModalTitle}}{{DistributorCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold required">Kode</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="DistributorCode" v-model="DistributorCode" class="font-weight-bold" />
                                <label id="errorDistributorCode" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow>
                             <CCol sm="2">
                                <label class="form-label font-weight-bold required">Nama</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="DistributorName" v-model="DistributorName" class="font-weight-bold" />
                                <label id="errorDistributorName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <hr>

                        <CTabs>
                            <CTab title="Profil" id="tab-1" active>
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Provinsi</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="Province" class="pb-3" :options="ProvinceData" v-model="Province" @input="onChange()" />
                                            <label id="errorProvince" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Kota</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="City" class="pb-3" :options="CityData" v-model="City"/>
                                            <label id="errorCity" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">Alamat</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                                            <label id="errorAddress" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Bebas Pajak</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="TaxFree"/> <label>Iya</label>
                                            </div>
                                            <label id="errorTaxFree" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Email</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="10">
                                            <CInput id="Email" v-model="Email" class="font-weight-bold" />
                                            <label id="errorEmail" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone1" v-model="Phone1" class="font-weight-bold" />
                                            <label id="errorPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone2" v-model="Phone2" class="font-weight-bold" />
                                            <label id="errorPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">No. HP</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone" v-model="HandPhone" class="font-weight-bold" />
                                            <label id="errorHandPhone" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Fax</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Fax" v-model="Fax" class="font-weight-bold" />
                                            <label id="errorFax" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">NPWP</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NPWP" v-model="NPWP" class="font-weight-bold" />
                                            <label id="errorNPWP" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Area</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="Area" class="font-weight-bold" :options="AreaData" :value.sync="Area" />
                                            <label id="errorArea" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Nama PT</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="PTName" v-model="PTName" class="font-weight-bold" />
                                            <label id="errorPTName" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Jenis</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="ProfileType" class="font-weight-bold" :options="ProfileTypeData" :value.sync="ProfileType" />
                                            <label id="errorProfileType" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">PIC</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="PIC" v-model="PIC" class="font-weight-bold" />
                                            <label id="errorPIC" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Posisi</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Position" v-model="Position" class="font-weight-bold" />
                                            <label id="errorPosition" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Catatan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                            <label id="errorNote" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Signature</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <div class="row">
                                                <div class="col-8 p-0 pl-3">
                                                    <div class="form-control">
                                                        <div v-if="this.SignatureStatus == 'Uploaded'">
                                                            <a href="#" @click="signatureClick()" class="label-file" id="uploadedSignature"><label>{{Signature}}</label></a>
                                                        </div>
                                                        <div v-if="this.SignatureStatus == 'New'">
                                                            <label class="label-file">{{Signature}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 p-0 px-2">
                                                    <label for="Signature" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                                    <input type="file" id="Signature" @input="onChangeSignature()" style="display:none"/>
                                                </div>
                                                <div class="col-2 p-0 pr-3">
                                                    <CButton type="button" @click="deleteSignatureClick()" id="deleteSignatureButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                                </div>
                                            </div>
                                            <label id="errorSignature" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CTab>
                            <CTab title="Sale" id="tab-2">
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Limit PO</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="3">
                                            <CInput type="number" id="POLimit" v-model="POLimit" class="font-weight-bold" />
                                            <label id="errorPOLimit" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Limit Tempo</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="3">
                                            <CInput type="number" id="LimitTempo" v-model="LimitTempo" class="font-weight-bold" />
                                            <label id="errorLimitTempo" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Inv. Jatuh Tempo (hari)</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="3">
                                            <CInput type="number" id="InvOverDue" v-model="InvOverDue" class="font-weight-bold" />
                                            <label id="errorInvOverDue" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Limit Disc Cash</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="3">
                                            <CInput type="number" id="DiscCashLimit" v-model="DiscCashLimit" class="font-weight-bold" />
                                            <label id="errorDiscCashLimit" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Jatuh Tempo Project (hari)</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="3">
                                            <CInput type="number" id="Project" v-model="Project" class="font-weight-bold" />
                                            <label id="errorProject" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Jatuh Tempo Retail (hari)</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="3">
                                            <CInput type="number" id="Retail" v-model="Retail" class="font-weight-bold" />
                                            <label id="errorRetail" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>

                                    <!-- overdue grid -->
                                    <datasource ref="dataSourceOverdue" :data="this.GridDataOverdue" :page-size="10" :schema-model-fields="this.SchemaModelOverdue"/>

                                    <kendo-grid ref="gridOverdue"
                                        :data-source-ref="'dataSourceOverdue'"
                                        :pageable="true"
                                        :editable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :toolbar="['create']"
                                        :cellClose="cellClose"
                                        :resizable="true"
                                        >

                                        <kendo-grid-column  :field="'group_type_name'"
                                                            :title="'Group Type'"
                                                            :editor= "groupTypeDropDownEditor"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'top_project'"
                                                            :title="'Jatuh Tempo (hari)'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :title="'&nbsp;'"
                                                            :attributes="{ class: 'k-text-center' }"
                                                            :command="['destroy']"
                                                            :width= 200></kendo-grid-column>
                                    </kendo-grid>
                                </div>
                            </CTab>
                            <CTab title="History Limit" id="tab-3">
                                <div class="p-3">
                                    <!-- grid -->
                                    <datasource ref="dataSourceHistoryLimit" :data="this.GridDataHistoryLimit" :page-size="10" :schema-model-fields="this.SchemaModelHistoryLimit"/>

                                    <kendo-grid ref="gridHistoryLimit"
                                        :data-source-ref="'dataSourceHistoryLimit'"
                                        :pageable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        >

                                        <kendo-grid-column  :field="'history_limit_date'"
                                                            :title="'Tanggal'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                            :attributes=" { 'class': 'k-text-center' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'history_limit_value'"
                                                            :title="'Nilai'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                            :attributes=" { 'class': 'k-text-right' }"></kendo-grid-column>

                                    </kendo-grid>
                                </div>
                            </CTab>
                            <CTab title="Sales" id="tab-4">
                                <div class="p-3">
                                    <!-- grid -->
                                    <datasource ref="dataSourceSales" :data="this.GridDataSales" :page-size="10" :schema-model-fields="this.SchemaModelSales"/>

                                    <kendo-grid ref="gridSales"
                                        :data-source-ref="'dataSourceSales'"
                                        :pageable="true"
                                        :editable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :toolbar="['create']"
                                        >

                                        <kendo-grid-column  :field="'sales_name'"
                                                            :title="'Nama'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'leader'"
                                                            :type="'boolean'"
                                                            :title="'Leader'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                            :attributes=" { 'class': 'k-text-center' }"
                                                            :template="`<input type='checkbox' class='k-checkbox' #= leader == true ? checked='checked' : disabled='disabled' # ></input>`"></kendo-grid-column>

                                    </kendo-grid>
                                </div>
                            </CTab>
                            <CTab title="BG" id="tab-5">
                                <div class="p-3">
                                    <CRow class="pb-3">
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Tanggal</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <date-picker
                                                v-model="BGDate"
                                                format="DD MMM YYYY"
                                                type="date"
                                                placeholder="Select date"
                                                value-type="YYYY-MM-DD"
                                                style="width:100%"
                                            ></date-picker>
                                            <label id="errorBGDate" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Tgl. Expire</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <date-picker
                                                v-model="BGExpireDate"
                                                format="DD MMM YYYY"
                                                type="date"
                                                placeholder="Select date"
                                                value-type="YYYY-MM-DD"
                                                style="width:100%"
                                            ></date-picker>
                                            <label id="errorBGExpireDate" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Extend (Hari)</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="BGExtend" v-model="BGExtend" class="font-weight-bold" />
                                            <label id="errorBGExtend" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Nilai</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="BGValue" v-model="BGValue" class="font-weight-bold" />
                                            <label id="errorBGValue" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Attachment</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <div class="row">
                                                <div class="col-8 p-0 pl-3">
                                                    <div class="form-control">
                                                        <div v-if="this.AttachmentStatus == 'Uploaded'">
                                                            <a href="#" @click="attachmentClick()" class="label-file" id="uploadedAttachment"><label>{{Attachment}}</label></a>
                                                        </div>
                                                        <div v-if="this.AttachmentStatus == 'New'">
                                                            <label class="label-file">{{Attachment}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 p-0 px-2">
                                                    <label for="Attachment" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                                    <input type="file" id="Attachment" @input="onChangeAttachment()" style="display:none"/>
                                                </div>
                                                <div class="col-2 p-0 pr-3">
                                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                                </div>
                                            </div>
                                            <label id="errorAttachment" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CTab>
                            <CTab title="Alamat Pengiriman" id="tab-6">
                                <div class="p-3">
                                    <!-- shipping address grid -->
                                    <datasource ref="dataSourceShippingAddress" :data="this.GridDataShippingAddress" :page-size="10" :schema-model-fields="this.SchemaModelShippingAddress"/>

                                    <kendo-grid ref="gridShippingAddress"
                                        :data-source-ref="'dataSourceShippingAddress'"
                                        :pageable="true"
                                        :editable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :toolbar="['create']"
                                        :resizable="true"
                                        >

                                        <kendo-grid-column  :field="'address'"
                                                            :title="'Alamat'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :title="'&nbsp;'"
                                                            :attributes="{ class: 'k-text-center' }"
                                                            :command="['destroy']"
                                                            :width= 200></kendo-grid-column>
                                    </kendo-grid>
                                </div>
                            </CTab>
                        </CTabs>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import distributorServices from '../Script/DistributorServices.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import FileViewer from '../../../../shared/FileViewer.vue';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'DistributorForm',
    components: {
        'date-picker' : datePicker,
        'file-viewer': FileViewer,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            SaveType : '',
            Error: 0,
            ProfileError: 0,
            SaleError: 0,

            //Profile
            Id : '',
            DistributorCode : '',
            Status : '',
            StatusData: [],
            DistributorName : '',
            Province : '',
            ProvinceData : [],
            City : '',
            CityData:  [],
            Address : '',
            Email : '',
            Phone1 : '',
            Phone2 : '',
            HandPhone : '',
            Fax : '',
            NPWP : '',
            Area : '',
            AreaData : [],
            PTName : '',
            ProfileType : '',
            ProfileTypeData : [{value: 'all', label: 'All'},
                        {value: 'project', label: 'Project'},
                        {value: 'retail', label: 'Retail'}],
            PIC : '',
            Position : '',
            Note : '',
            TaxFree : false,

            Signature: '',
            SignatureStatus: '',

            //Sale
            POLimit : 0,
            DiscCashLimit : 0,
            LimitTempo: 0,
            InvOverDue: 0,
            Project : 0,
            Retail : 0,
            GroupTypeData : [],

            //BG
            BGDate : '',
            BGExpireDate : '',
            BGExtend : '',
            BGValue : '',

            Attachment: '',
            AttachmentStatus: '',

            //grid
            GridDataShippingAddress: [],
            SchemaModelShippingAddress: {
                address: { type: "string", editable: true, validation: {required: true}},
            },

            GridDataOverdue: [],
            SchemaModelOverdue: {
                group_type_id: { type: "string", editable: true, validation: {required: true}, hidden: true},
                group_type_name: { type: "string", editable: true, validation: {required: true}},
                top_project: { type: "number", editable: true, validation: {required: true}}
            },

            GridDataHistoryLimit: [],
            SchemaModelHistoryLimit: {
                history_limit_date: { type: "string" },
                history_limit_value: { type: "number" }
            },

            GridDataSales: [],
            SchemaModelSales: {
                sales_name: { type: "string", editable: true, validation: {required: true}},
                leader: { type: "boolean", editable: true, }
            },
        }
    },
    methods: {
        onChangeSignature(){
            var signature = document.getElementById('Signature').files[0];

            this.SignatureStatus = 'New';
            this.Signature = signature.name;
        },
        signatureClick(){
            this.$refs.fileViewer.viewClick(this.Signature);
        },
        onChangeAttachment(){
            var attachment = document.getElementById('Attachment').files[0];

            this.AttachmentStatus = 'New';
            this.Attachment = attachment.name;
        },
        attachmentClick(){
            this.$refs.fileViewer.viewClick(this.Attachment);
        },
        async addClick(){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add Distributor: ';
            this.DistributorCode = 'Auto';
            this.DistributorName = '';
            this.StatusData = [  {value: 'N', label: 'New'}];
            this.Status = 'N';

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == "DKI Jakarta");
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == "Jakarta Utara");

            this.Address = '';
            this.Email = '';
            this.Phone1 = '';
            this.Phone2 = '';
            this.HandPhone = '';
            this.Fax = '';
            this.NPWP = '';
            this.AreaData = await distributorServices.getAreaQuery();
            this.Area = this.AreaData[0].value;
            this.PTName = '';
            this.ProfileType = this.ProfileTypeData[0].value;
            this.PIC = '';
            this.Position = '';
            this.Note = '';
            this.TaxFree = false;

            //File
            this.Signature = '';
            this.SignatureStatus = 'New';

            this.POLimit = 0;
            this.LimitTempo = 0;
            this.InvOverDue = 0;
            this.DiscCashLimit = 0;
            this.Project = 0;
            this.Retail = 0;
            
            //Group Type
            this.GroupTypeData = await distributorServices.getGroupTypeQuery();

            this.BGDate = '';
            this.BGExpireDate = '';
            this.BGExtend = '';
            this.BGValue = '';

            //File
            this.Attachment = '';
            this.AttachmentStatus = 'New';

            //Grid
            this.GridDataShippingAddress = [];
            this.GridDataHistoryLimit = [];
            this.GridDataSales = [];
            this.GridDataOverdue = [];

            this.SaveType = 'Add';
            $('#tab-1').trigger('click');
            document.getElementById('DistributorCode').readOnly = false;
            window.$('#distributorModal').modal('show');
        },
        async editClick(id, View){
            var data = await distributorServices.getDetailContact(id);

            $(".form-error").css("display", "none");
            $(".save-button").show();

            this.ModalTitle = 'Edit Distributor: ';
            this.Id = data.contact_id;
            this.DistributorCode = data.contact_code;
            this.DistributorName = data.contact_name;

            this.StatusData = [ 
                {value: 'N', label: 'New'},
                {value: 'A', label: 'Active'},
                {value: 'I', label: 'Inactive'}
            ];
            this.Status = data.status;

            var profileData = data.metadata.profile;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == profileData.province);
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == profileData.city);

            this.Address = profileData.address;
            this.Email = profileData.email;
            this.Phone1 = profileData.phone1;
            this.Phone2 = profileData.phone2;
            this.HandPhone = profileData.handphone;
            this.Fax = profileData.fax;
            this.NPWP = profileData.npwp;
            this.Area = profileData.area;
            this.AreaData = await distributorServices.getAreaQuery();
            this.PTName = profileData.ptname;
            this.ProfileType = profileData.profile_type;
            this.PIC = profileData.pic;
            this.Position = profileData.position;
            this.Note = profileData.note;
            this.TaxFree = profileData.taxfree;

            //File
            this.Signature = data.attachment1;
            this.SignatureStatus = 'Uploaded';

            var sale = data.metadata.sale;
            this.POLimit = sale.po_limit;
            this.LimitTempo = sale.limit_tempo;
            this.InvOverDue = sale.inv_over_due;
            this.DiscCashLimit = sale.disc_cash_limit;
            this.Project = sale.project;
            this.Retail = sale.retail;

            //Group Type
            this.GroupTypeData = await distributorServices.getGroupTypeQuery();

            var bg = data.metadata.bg;
            this.BGDate = bg.bg_date;
            this.BGExpireDate = bg.bg_expire_date;
            this.BGExtend = bg.bg_extend;
            this.BGValue = bg.bg_value;

            //File
            this.Attachment = data.attachment2;
            this.AttachmentStatus = 'Uploaded';

            //grid
            this.GridDataShippingAddress = this.$globalfunc.objectToArrayConverter(data.metadata.shipping_address, "Distributor-ShippingAddressData");
            this.GridDataHistoryLimit = data.metadata.history_limit;
            this.GridDataSales = data.metadata.sales;
            if(data.contact_group_types != null ){
                this.GridDataOverdue = this.$globalfunc.objectToArrayConverter(data.contact_group_types, "Distributor-OverdueData");
            }
            
            this.SaveType = 'Edit';
            document.getElementById('DistributorCode').readOnly = true;
            $('#tab-1').trigger('click');

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Distributor: ';
            }

            window.$('#distributorModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            this.ProfileError = 0;
            this.SaleError = 0;

            $(".form-error").css("display", "none");

            if(this.DistributorCode == ''){
                this.errorShow('errorDistributorCode');
            }
            if(this.DistributorName == ''){
                this.errorShow('errorDistributorName');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
                this.ProfileError++;
            }
            if(this.City == '' || this.City == null){
                this.errorShow('errorCity');
                this.ProfileError++;
            }
            if(this.NPWP == ''){
                this.errorShow('errorNPWP');
                this.ProfileError++;
            }
            if(this.Area == ''){
                this.errorShow('errorArea');
                this.ProfileError++;
            }
            if(toString(this.Project) == '' || toString(this.Project) == null){
                this.errorShow('errorProject');
                this.SaleError++;
            }
            if(toString(this.Retail) == '' || toString(this.Retail) == null){
                this.errorShow('errorRetail');
                this.SaleError++;
            }

            if(this.ProfileError != 0){
                this.$swal("Error", "Terdapat error pada tab profil", "error");
            }
            else{
                if(this.SaleError != 0){
                    this.$swal("Error", "Terdapat error pada tab sale", "error");
                }
            }
        },
        saveClick() {
            this.inputValidation();

            var signature = document.getElementById('Signature').files[0];
            if(signature == undefined)(
                signature = null
            )

            var attachment = document.getElementById('Attachment').files[0];
            if(attachment == undefined)(
                attachment = null
            )

            // history limit grid validation
            var historyLimitGridData = this.$refs.gridHistoryLimit.kendoWidget().dataSource._data;
            var historyLimitData = [];
            for (let i = 0; i < historyLimitGridData.length; i++) {
                var str = { history_limit_date: historyLimitGridData[i].history_limit_date,
                            history_limit_value: historyLimitGridData[i].history_limit_value}
                historyLimitData.push(str)
            }
            var newHistoryLimit = { history_limit_date: moment(new Date()).format("YYYY-MM-DD"),
                                    history_limit_value: parseInt(this.POLimit)};
            historyLimitData.push(newHistoryLimit);

            // sales grid validation
            var salesGridData = this.$refs.gridSales.kendoWidget().dataSource._data;
            var salesData = [];
            if(salesGridData.length > 0){
                for (let i = 0; i < salesGridData.length; i++) {
                    var str = { sales_name: salesGridData[i].sales_name,
                                leader: salesGridData[i].leader}
                    salesData.push(str)
                }
                var salesGridValidation = distributorServices.nameDuplicateValidation(salesData);

                if(salesGridValidation == true){
                    this.$swal("Error", "Nama pada grid di tab sales tidak boleh duplikat", "error");
                    this.Error++;
                }
            }

            // overdue grid validation
            var overdueGridData = this.$refs.gridOverdue.kendoWidget().dataSource._data;
            var overdueData = [];
            if(overdueGridData.length > 0){
                for (let i = 0; i < overdueGridData.length; i++) {
                    var str = { group_type_id: overdueGridData[i].group_type_id,
                                top_project: overdueGridData[i].top_project,
                                top_retail: overdueGridData[i].top_project }
                    overdueData.push(str)
                }
                var overdueGridValidation = distributorServices.groupTypeDuplicateValidation(overdueData);
                
                if(overdueGridValidation == true){
                    this.$swal("Error", "Group type pada grid di tab sale tidak boleh duplikat", "error");
                    this.Error++;
                }
            }

            //shipping Address validation
            var shippingAddressGridData = this.$refs.gridShippingAddress.kendoWidget().dataSource._data;
            var shippingAddressData = [];
            for (let i = 0; i < shippingAddressGridData.length; i++) {
                shippingAddressData.push(shippingAddressGridData[i].address)
            }

            if(shippingAddressData.length < 1){
                shippingAddressData.push(this.Address);
            }else {
                if(this.SaveType == 'Add'){
                    shippingAddressData.push(this.Address);
                }
                var shippingAddressDataValidation = distributorServices.addressDuplicateValidation(shippingAddressData);
                if(shippingAddressDataValidation == true){
                    this.$swal("Error", "alamat pada grid di tab alamat shipping tidak boleh duplikat", "error");
                    this.Error++;
                }
            }
            
            if(this.Error == 0){
                const profile = {
                    province : this.Province.value,
                    city : this.City.value,
                    address : this.Address,
                    email : this.Email,
                    phone1 : this.Phone1,
                    phone2 : this.Phone2,
                    handphone : this.HandPhone,
                    fax : this.Fax,
                    npwp : this.NPWP,
                    area : this.Area,
                    ptname : this.PTName,
                    profile_type : this.ProfileType,
                    pic : this.PIC,
                    position : this.Position,
                    note : this.Note,
                    taxfree : this.TaxFree
                };

                const sale = {
                    po_limit : parseInt(this.POLimit),
                    limit_tempo : parseInt(this.LimitTempo),
                    inv_over_due : parseInt(this.InvOverDue),
                    disc_cash_limit : parseInt(this.DiscCashLimit),
                    project : parseInt(this.Project),
                    retail : parseInt(this.Retail),
                };

                const history_limit = historyLimitData;

                const sales = salesData;

                const bg = {
                    bg_date : this.BGDate,
                    bg_expire_date : this.BGExpireDate,
                    bg_extend : parseInt(this.BGExtend),
                    bg_value : parseInt(this.BGValue)
                }

                const shipping_address = shippingAddressData;

                const metadata = {
                    profile : profile,
                    sale : sale,
                    history_limit : history_limit,
                    sales : sales,
                    bg : bg,
                    shipping_address : shipping_address
                }

                const distributor = {
                    contact_code : this.DistributorCode,
                    contact_type : "Distributor",
                    contact_name : this.DistributorName,
                    status : this.Status,
                    metadata : metadata,
                    attachment1: signature,
                    attachment2: attachment,
                    contact_group_types: overdueData
                }

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : distributor
                    }

                    distributorServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#distributorModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : distributor
                    }

                    distributorServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#distributorModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        onChange(){
            this.City = '';
            if (this.Province == null){
                this.CityData = []
            }
            else{
                this.CityData = this.$globalfunc.getCityData(this.Province.value);
            }
        },
        deleteSignatureClick(){
            $('#Signature').val('');
            this.SignatureStatus = '';
            this.Signature = '';
        },
        deleteAttachmentClick(){
            $('#Attachment').val('');
            this.AttachmentStatus = '';
            this.Attachment = '';
        },
        groupTypeDropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "label",
                    filter: "contains",
                    dataSource: {
                        data: vue.GroupTypeData
                    }
                });
        },
        cellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());

            var groupTypeData = this.GroupTypeData.find(c => c.label == dataItem.group_type_name);
            dataItem.group_type_id = groupTypeData.value;
        }
    }   
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>